<template>
  <Guest>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-2xl leading-9 font-extrabold text-white uppercase">
        <i18n-t keypath="register.wrapup.title" />
      </h2>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <p>
              <i18n-t keypath="register.wrapup.conclusion">
                  <template v-slot:price>
                      € {{ hour_price_estimation }}
                  </template>
              </i18n-t>
          </p>
          <p>
              <i18n-t keypath="register.wrapup.restart">
                  <template v-slot:click>
                      <router-link :to="{name: 'register.steps.questions', params: {id: 1}}" class="link">
                          <i18n-t keypath="register.wrapup.click_here" />
                      </router-link>
                  </template>
              </i18n-t>
          </p>
          <TextInput v-model:value="hour_price" v-model:errors="errors.hour_price" :label="$t('register.wrapup.hour_price.title')" :placeholder="$t('register.wrapup.hour_price.placeholder')" class="pt-5" />

          <!-- For debugging:  -->
          <!-- <a @click.prevent="consoleLogStore">DUMP STORE</a> -->
      </div>
      <div class="mt-6">
        <span @click="goToPreviousStep" class="inline-block w-5/12 sm:w-3/12 rounded-md shadow-sm">
            <button type="button" class="button-transparent w-full">
                <i18n-t keypath="general.back" />
            </button>
        </span>
        <span @click="goToNextStep" class="inline-block w-5/12 rounded-md shadow-sm float-right">
            <button type="button" class="button w-full">
                <i18n-t keypath="general.save" />
            </button>
        </span>
      </div>
    </div>
  </Guest>
</template>

<script>
import TextInput from "@/components/forms/TextInput";
import { sendWalkthrough, getWalkthroughQuestions } from "@/helper/api/RegistrationHelper";
import { walkthrough_to_store } from "@/helper/store/walkthrough_to_storage";
import Guest from "@/wrappers/Guest";
import { first_registration_step } from "@/router/router-config";
import messages from "@/helper/store/messageHelper";
import { setLoading } from "@/helper/store/loadingSpinnerHelper";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
    name: "WrapupStep",
    components:{
        Guest,
        TextInput,
    },
    created(){
        getWalkthroughQuestions()
            .then(data => {
                this.questions = data;
                // console.log('refreshing questions:' , data);
            })
            .catch(err => {
                console.error(err);
            });
    },
    data(){
        return {
            questions: [],
            pricing_steps: [
                { points_from: 36,    points_to: Infinity,    price_from: "200" }, 
                { points_from: 25,    points_to: 35,          price_from: "125" }, 
                { points_from: 17,    points_to: 24,          price_from: "90" }, 
                { points_from: 12,    points_to: 16,          price_from: "75" }, 
                { points_from: 8,     points_to: 11,          price_from: "60" }, 
                { points_from: 5,     points_to: 7,           price_from: "50" }, 
                { points_from: 0,     points_to: 4,           price_from: "40" },
            ],
            errors: {
                hour_price: [], 
            },
        }
    },
    computed: {
        hour_price_estimation: function (){
            const pricing_step = this.pricing_steps.find(step => step.points_from <= this.points && this.points <= step.points_to);
            if (pricing_step === undefined){
                return 0;
            }
            return pricing_step.price_from;
        },
        hour_price: walkthrough_to_store('hour_price'),
        points: function (){
            const answers = Object.assign({}, this.$store.getters['walkthrough/form']).answers; 
            const options = this.questions.map(({options}) => options).flat(1);

            let sum = 0;
            for (const question of Object.values(answers)) {
                const ans_id = question.answer.id;

                const ans = options.find((option) => option.id == ans_id);
                if (ans){
                    sum += ans.weight;
                }
            }
            return sum;
        },
    },
    methods: {
        goToNextStep: function(){
            // No next route; end of form.
            setLoading(true);

            sendWalkthrough()
                .then(()=>{
                    // console.log('Walkthrough succes');
                    this.$router.push("/dashboard");
                })
                .catch((err)=>{
                    console.log(err);

                    this.$store.commit('walkthrough/setErrors', err);
                    
                    if (Object.keys(err.errors).length > 1 || ! ('hour_price' in err.errors)) {
                        // more errors than just on this page:
                        messages.error($t('register.errors.multiple'));
                        this.$router.push({name: first_registration_step})
                    } else {
                        this.errors = err.errors
                        messages.warn($t('register.errors.generic'));
                    }
                    
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        goToPreviousStep: function(){
            this.$router.go(-1);
        },
        // consoleLogStore: function(){
        //     console.log(this.$store.state.walkthrough);
        // },
    },
    
}
</script>

<style scoped>

</style>
